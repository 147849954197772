
@import "../../../app-customs/config-scss/variables.scss";


.link-btn-container {
    display: flex;
    flex-shrink: 0;
    margin-right: 0.3em;
    // margin-bottom: 1.1em;
    padding: .3em 0;
    justify-content: flex-end;
    align-items: center;
    clear          : both;
}

.link-btn {
    padding       : .3em .7em .2em;
    font-weight   : bold;
    text-align    : center;
    text-transform: none;
    color         : white;
    border-radius : 3px;
    cursor        : pointer;
    background-color: $color3;
    transition    : opacity .4s;
    display: flex;
    align-items: center;
    opacity: 1;
    max-width: 40%;

    &:active {
        opacity: 0.3;
    }

    &-type {
        opacity: 0.4;
        font-size: 0.9em;
        margin-right: 8px;
    }

    &-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
    }
}

.link-btn-icon {
    margin-left: 0.7em;
    margin-bottom: -2px;

    &::before {
        background-color: white !important;
    }
}
