
@import "../../../app-customs/config-scss/variables.scss";

#ud-content-container {
    display: flex;
    flex-direction: column;

    .t-tab-selected {
        border-bottom-color: $fdSelected;
    }
}
.ud-tab {
    font-weight: bold;
}
