@import "../../../app-customs/config-scss/variables.scss";

.contributions-section {
    width: 100%;
    padding: 0.7em .3em;
    box-sizing: border-box;

    .contributions-date {
        text-align: left;
        flex-grow: 1;
    }

    .contributions-empty {
        text-align: left;
        flex-grow: 1;
        font-style: italic;
        color: red;
        font-size: 13px;
    }

    .contributions-refresh-btn {
        padding       : .3em .7em .2em;
        font-weight   : bold;
        text-align    : center;
        text-transform: none;
        color         : white;
        border-radius : 3px;
        cursor        : pointer;
        background-color: $color2;
        transition    : opacity .4s;
        opacity: 1;
        display: flex;
        align-items: center;

        &:active {
            opacity: 0.3;
        }
    }

    .contributions-refresh-btn-icon {
        margin-left: 0.7em;
        margin-bottom: -2px;

        &::before {
            background-color: white !important;
        }
    }

    .disable-refresh {
        opacity: 0.3 !important;
        cursor: default;
    }

    .contributions-current-title {
        font-weight   : bold;
        color         : $color1;
        margin-bottom: .3em;
    }

    .contributions-modal-title {
        margin-bottom: .7em;
    }

    .contributions-current-details {
        background-color: $color1;
        padding: 0.7em .3em;
        color: white;

        h4 {
            color: inherit;
            font-weight   : bold;
        }
    }

    .contributions-header {
        width: 100%;
        display: flex;
        justify-content: start;
    	align-items: center;
        box-sizing: border-box;
        margin-bottom: .3em;

        .contributions-section-title {
            flex-grow: 1;
            font-weight: bold;
        }
    }

    .contributions-body {
        border: 1px solid #eee;
        text-align: left;
    }

    .contributions-item {
        padding: 0.7em .3em;
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
    }

    .contributions-item:last-child {
        border-bottom: none;
    }

    .contributions-item-details {
        flex-grow: 1;
    }

    .contributions-item-time {
        margin-left: 10px;
        width: 70px;
        font-style: italic;
        color: red;
        flex: none;
        font-size: 13px;
    }

    .simple-text {
        //font-size: 22px;
        //line-height: 26PX;
    }

    h4 {
        margin: 0;
        word-break: break-word;
    }
}

.flex {
    display: flex;
    justify-content: start;
    align-items: center;
}

.contributions-separator {
    border-bottom: 1px solid #eee;
    // opacity: 0.7;
    width: 100%;
}

.invisible {
    display: none;
}

[id=contributions-dialog] {

    .contributions-modal {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        display: flex;
        flex-direction: column;
        max-height: 100%;
        box-sizing: border-box;
        padding: 0 .3em !important;

        .contributions-body {
            overflow-x: hidden;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        .contributions-item {
            font-size: 13px;
        }
    }

    .close-contributions-dialog {
        padding: 0.4em;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.5em;
    }
}
