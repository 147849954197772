@import "../../../app-customs/config-scss/variables.scss";


.contextual-search-results {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.list-search-content {
    display       : flex;
    flex-direction: column;
    flex-grow     : 1;
    flex-shrink   : 1;
    overflow-y    : auto;
    font-size     : 1em;

    & > div {
        flex-shrink: 0;
    }

    & > div:only-child .type-bar {
        display: none;
    }

    .list-empty {
        text-align: center;
        margin-top: 100px;
        font-size: 0.9em;
    }

    .list-el-event {
        .start-date {
            font-weight: 700 !important;
            font-size: 1em !important;
            color: $color1 !important;
        }
    }
}
