
@import "../../app-customs/config-scss/variables.scss";


/* Warning, duration is defined is JS too (@see setTimeout/EFFECT_DURATION) */
$effectDuration: .2s;


@mixin tile-click-effect() {

    transition: transform $effectDuration;
    &.animate {
        transform: scale($tileEffectScale);

        & .effect-container {
            opacity: $tileEffectOpacity;
        }
    }
    .effect-container {
        position  : absolute;
        display   : inline-block;
        top       : 0;
        left      : 0;
        width     : 100%;
        height    : 100%;
        opacity   : 0;
        transition: opacity $effectDuration;
        background-color: $tileEffectColor;
    }
}