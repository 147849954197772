@import "../../../app-customs/config-scss/variables.scss";

.media-content-container {
    display: flex;
    flex-direction: column;
}

.media-timeline {
    overflow-y: hidden;
    height: 100%;
    justify-content: flex-start;
    align-content: center;
    display: flex;
    flex-direction: column;

    & > div:last-child {
        flex-grow: 1;
        -webkit-overflow-scrolling: touch;
    }
}

.facebook-page-timeline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll
}

.tableView {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;

    .platform-timeline {
        width: 100%;
    }
}

.media-button {
    width: 100%;
    height: 100%;
}

.media-twitter-post {
    margin: 0 0 8px 0;
}

.media-instagram-post {
    margin: 8px 0;
}

.media-youtube-post, .media-facebook-post {
    margin: 16px 0;
}

.media-instagram-post > div, .media-facebook-post > div, .media-twitter-post > div, .media-youtube-post  {
    display: flex;
    justify-content: center;
    width: 100%;
}

.fixed-spinner {
    width: 100%;
    height: 34px;
    position: fixed;
    z-index: - 1;
    display: flex;
    justify-content: center;

    &.top {
        top: 40px;
    }

    &.bottom {
        bottom: 40px;
    }

    &.hover {
        z-index: 10000000;
    }

    .spinner-wrapper {
        border-radius: 18px;
        width: 34px;
        height: 34px;
        background-color: #FFF;
        transform: scale(0);
        transition: transform 0.4s;

        &.display {
            transform: scale(1)
        }
    }
}

.infinit-table-spinner {
    border: 1px solid $color1;
    border-right-color: transparent;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    margin: 8px auto 8px auto;

    &.rotate {
        animation: rotate-spinner 1s infinite linear;
    }

    &.initial {
        margin-top: 100px !important;
    }
}

.refresh-spinner-container {
    position: fixed;
    width: 100%;
    height: 34px;
    display: flex;
    justify-content: center;
    z-index: 100000;
}

.refresh-spinner-wrapper {
    border-radius: 18px;
    width: 34px;
    height: 34px;
    z-index: 10000000;
}


@keyframes rotate-spinner {
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}

// material ui tabs css oevrrides
@media (min-width: 960px) {
  .jss10 {
    min-width: 0 !important;
  }

  .MuiTab-root-10 {
    min-width: 0 !important;
  }
}

.MuiTab-fullWidth-17 {
    min-width: 0 !important;
}

// .fb-post > span > iframe {
//     margin: 0 auto;
//     width: 100% !important;;
//     height: auto !important;
// }
//
// .fb_iframe_widget iframe {
//     width: 100% !important;;
//     height: auto !important;
// }
