
@import "../../../app-customs/config-scss/variables.scss";


div[aria-hidden=true] > .sa-grid-container {
    opacity: 0;
    pointer-events: none;
}

.sa-grid-container {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity .4s;
}

.sa-grid {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}


// PLACES ROW
.sa-grid-places {
    position: fixed;
    z-index: 2;
    display: flex;
    height: $synoPlacesRowHeight;
    padding-left: $synoHoursColumnWidth;
    background-color: $synoBackgroundColor;
    will-change: transform;
    pointer-events: none;
}
.sa-grid-place {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 $synoColumnWidth;
    min-width: 0;
    text-align: center;
    overflow: hidden;
    border-bottom: 1px solid $synoBorderColor;
    background-color: $synoBackgroundColor;

    span {
        max-height: 3.1em;
        min-width: 0;
        padding: 0 .5em;
        line-height: 1em;
        word-break: break-word;
    }
}
.sa-grid-place:first-child {
    border-left: 1px solid $synoBorderColor;
}
.sa-grid-place:last-child {
    border-right: 1px solid $synoBorderColor;
}


// HOURS COLUMN
.sa-grid-hours {
    position: fixed;
    z-index: 1;
    padding-top: $synoPlacesRowHeight;
    text-align: center;
    background-color: $synoBackgroundColor;
    will-change: transform;
    pointer-events: none;
}
.sa-grid-hour {
    height: 1.3em;
    border-bottom: 1px solid $synoBorderColor;

    div {
        width: $synoHoursColumnWidth;
    }
}
.sa-grid-hour:nth-child(2n) {
    background-color: #ececec;
}


// CONTENT (EVENTS)
.sa-grid-content {
    display: flex;
    margin-top: $synoPlacesRowHeight;
    margin-left: $synoHoursColumnWidth;
}

$eventColumnBorderXWidth: 1px;
.sa-grid-events-column {
    border-left: $eventColumnBorderXWidth solid $synoBorderColor;
}
.sa-grid-events-column:last-child {
    border-right: $eventColumnBorderXWidth solid $synoBorderColor;
}

$eventBorderXWidth: 1px;
.sa-grid-event {
    position: relative;
    width: $synoColumnWidth - $eventBorderXWidth * 2 - $eventColumnBorderXWidth;
    overflow: hidden;
    border-width: 0 $eventBorderXWidth 2px $eventBorderXWidth;
    border-style: solid;
    border-color: $synoBackgroundColor;
    color: $synoEventTextColor;

    & > div {
        display: flex;
        height: inherit;
        flex-direction: column;
        padding: .2em;
        pointer-events: none;
        line-height: 1.1em;
        font-size: .9em;
        text-align: center;
        word-break: break-word;
    }
}
.sa-grid-event-time {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin-bottom: .2em;
    font-size: .9em;
}
.sa-grid-event-desc {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;

    span {
        width: 100%;
    }
}

.sa-grid-event-type {
    margin-bottom: 0.6em;
    font-size: .9em;
    font-weight: bold;
}