
@import "../../../app-customs/config-scss/variables.scss";


/* used to display a special icon (depending on item data, e.g. focus innovation) */
$imageSize: 40px;
.title-image {
    flex  : 0 0 40px;
    height: $imageSize;
    margin: 0 .4em;
    background-repeat: no-repeat;
    background-size  : cover;
}
.is-ios .title-image {
    margin-top: .25em;
}


.title-image-small {
    flex  : 0 0 25px;
    height: $imageSize / 2;
    margin: 0 .1em;
    background-repeat: no-repeat;
    background-size  : cover;
}

.prop-name.title {
    flex-wrap : nowrap;
    padding   : .3em;
    background-color: $color1;
    user-select: none;

    .prop-desc {
        z-index       : 0;
        flex-grow     : 1;
        display       : flex;
        align-items   : center;
        line-height   : 1em;
        font-weight   : 600;
//        text-transform: uppercase;
        color         : $titleFontColor;
    }

    // Add a small margin when fav icon is not displayed
    // .prop-desc:last-child {
    //     margin-bottom: .2em;
    // }

    b {
        margin: 0 .3em;
    }
}

.prop-name.title.title-flex {
    display: flex;
    align-items: center;

    // Fix a very weird bug on ios resulting in title bar height being >1000px ...
    min-height: $favIconSize;
    .icon-font {
        min-width: $favIconSize;
    }
}

@media (min-width:768px) {
    span.fav-btn{
        font-size:1em;
        width: 0.7em !important;
    }
}

// Fix IE
.is-ie .prop-name.title .prop-desc {
    padding-top: .7em;
    padding-bottom: .6em;
}
@media (min-width:768px) {
    .prop-name.title {
        .star {
            line-height: 1.25em;
            color: $favIconColor;
        }
    }
}
