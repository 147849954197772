
@import "../../../app-customs/config-scss/variables.scss";

.contact-btn {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: auto;
    padding: .5em .7em;
    background-color: $color3;
    transition: opacity .5s;
    border-radius: 10px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.contact-btn[disabled] {
    opacity: .5;
    cursor: default !important;
}

.contact-btn-icon {
    margin: auto;
    margin-right: .2em;
    font-size: 1.5em;
    fill: white;
    pointer-events: none;
}

.contact-btn-text {
    display: inline-flex;
    align-items: center;
    line-height: 1;
    margin: 0 .5em 0;
    color: white;
    pointer-events: none;
}

.contact-btn-loader {
    font-size: .9em;
}
