
@import "../../../app-customs/config-scss/variables.scss";

.side-index {
    display         : flex;
    flex-direction  : column;
    flex-shrink     : 0;
    flex-basis      : 28px;
    justify-content : space-around;
    list-style      : none;
    margin          : 0;
    padding         : 0;
    height          : auto;
    font-size       : 12px;
    text-align      : center;
    color           : $sideIndexColor;
    //background-color: $sideIndexBackgroundColor;
    background-image: $sideIndexGradient;
    overflow        : hidden;
    overscroll-behavior: none;
}

li.list-index-element {
    flex-shrink: 1;
    flex-grow  : 1;
    cursor     : pointer;
    user-select: none;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    font-weight: bold;
    -webkit-tap-highlight-color: transparent;

    * {
        pointer-events: none;
    }
}

.list-index-element-selected {
    background-color: $sideIndexSelectedBackgroundColor;
    color: $sideIndexSelectedColor;
}

@media screen and (max-height: 650px) {
    .side-index {
        font-size: .6em;
    }
}
