
@import "../../../app-customs/config-scss/variables.scss";


[id=search-place-dialog] {

    .list-dialog-content {
        padding: 0 .5em .5em;
        font-size: .9em;
    }

    .no-result {
        margin: .5em 0 .3em;
    }

    .search-field-input-container {
        flex-basis: 75%;
    }
}

/* rules for tiny screens */
@media screen and (max-height: 780px) {

    [id=search-place-dialog] {

        .search-field-container {
            padding: .3em .2em 0 .4em;
        }

        .list-dialog-content {
            margin-top: .2em;
        }
    }
}

#sp-inner {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.sp-buttons-container {
    display: flex;

    &.modal {
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    &.start {
        justify-content: flex-end;
    }
    &.dest {
        justify-content: space-between;
        align-items    : center;
    }

    // Common button style
    & > div {
        margin       : 0 .4em .4em;
        padding      : .4em .7em .3em;
        font-weight  : bold;
        color        : white;
        border-radius: 3px;
        cursor       : pointer;
    }

    .btn-edit-start {
        font-size: .9em;
        height   : fit-content;
        padding-bottom: .4em;
        background-color: #949FA7;
    }

    .btn-choose-dest,
    .btn-compute-route {
        background-color: $ctaBtnBackgroundColor;
    }

    .btn-compute-route {
        padding: .6em 1.6em;
    }
}
