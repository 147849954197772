
@import "../../../app-customs/config-scss/variables.scss";


div.save-contact-btn{
    margin   : .2em .2em 0;
    font-size: 2em;
    min-width: 1em;
    min-height: 1em;
    color    : $shareBtnColor;
    cursor   : pointer;
    width: 1em;
    -webkit-tap-highlight-color: transparent;
}

.is-mobile div.save-contact-btn {
    padding-top: 0;
}

@media (min-width:768px) {
    div.save-contact-btn {
        font-size: 1.4em;
        min-width: 1.4em;
        min-height: 1.4em;
    }
}