
@import "../../../app-customs/config-scss/variables.scss";


.detail-speakers-component {
    margin: .6em 0 .35em;

    .list-component {
        padding-left: .4em;
    }

    .type-bar.horizontal-separator {
        justify-content: space-around;
        background-color: $pageBackgroundColor;

        .type-bar-label {
            color: $fontColor;
            font-weight: normal;
            text-align: left;
        }

        img {
            height: $listElementImageSize;
            margin: 0 .5em;
        }
    }

    .type-bar-content {
        margin: 0 0.2em .5em;
        padding: 0 .3em .3em .3em;
        // border: 1px solid lightgrey;
        background-color: #0e354c0f;
        border-radius: .3em;
    }

    .type-bar-content:not(.type-bar-content-closed) {
        height: auto !important;
    }

    .free-row {
        margin-top: 0 !important;
    }
}
