
@import "../../../app-customs/config-scss/variables.scss";


.search-field-container {
    display: flex;
    flex-shrink: 0;
    padding: .3em 0;
    // background-color: $searchFieldBackgroundColor;

    input {
        flex-grow : 1;
        min-height: 22px;
        padding   : .6em 1em .5em;
        font-size : 16px; // minimum font-size to avoid zoom (despite viewport metatag) on safari mobile!
        border    : 0;
        outline   : none; // focus feedback on desktop browser
    }
    // placeholder
    input[placeholder]          { font-size: .9em; text-overflow: ellipsis; }
    input::-moz-placeholder     { font-size: .9em; text-overflow: ellipsis; }
    input:-moz-placeholder      { font-size: .9em; text-overflow: ellipsis; }
    input:-ms-input-placeholder { font-size: .9em; text-overflow: ellipsis; }
    justify-content: center;
    margin: 0;
}

//.search-field-contextual

.search-field-clear {
    padding: 0 .2em;
    //font-size: 1.2em;
    margin-right: .3em;
    color: $searchFieldClearButtonColor;
    background-color: $searchFieldClearButtonBgColor;
    cursor: pointer;
    transition: opacity .5s;
    border-radius: 4px;
    -webkit-tap-highlight-color: transparent;
}

.text-field-clear-icon{
    height: 1em;
    width: 1em;
    background-color: $searchFieldClearButtonBgColor;
    fill: white;
    stroke: none;
}

.search-field-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 0;
    border: 1px solid $searchFieldBorderColor;
    border-radius: 2px;
}

.search-field-cancel-container {
    display: flex;
    align-items: center;
    justify-content:center;
    padding: 0 .5em;
}
/* .search-field-cancel {
    font-size : 1.5em !important;
    color     : $searchFieldBorderColor;
    cursor    : pointer;
    -webkit-tap-highlight-color: transparent;
} */

// Search field inside app toolbar
.app-toolbar {
    .search-field-container input {
        margin-right: .5em;
        padding: .3em 1em .2em;
        width: 70%;
        flex-grow: .8;
    }
    .search-field-input-container {
        max-width: 300px;
    }

    // Transitions - see CSSTransition usage in AppToolbar

    .search-field-container,
    .search-field-exit-done {
        width: 0; // Hack so the input is considered visible by the browser so focusable on iOS
        overflow: hidden;
        opacity: .01;
    }

    .search-field-enter,
    .search-field-enter-done {
        width: 70%;
        transition: width .6s ease-in, opacity .6s ease-in;
    }
    .search-field-enter,
    .search-field-exit {
        opacity: .01;
    }
    .search-field-enter-done {
        opacity: 1;
    }
}