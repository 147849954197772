@import "../../../app-customs/config-scss/variables.scss";

.paper-title {
    padding: 0 1.1em;

    h2 {
        font-size: 1.1em !important;
        font-weight: bold;
    }
}

.paper-text {
    padding: 0 1.1em;
    text-align:justify;
    ul {
    	text-align:left;
    }
}

.paper-authors {
    font-size: 18px;
}


.btn-show-pdf {
    flex-direction: row;
    display: flex;
    border-radius: 0.5rem;
    background-color: $color2;
    padding: 0.5rem 1rem;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    color : white;
        
    align-items: end;
    margin: 20px 20px 20px auto;
    text-align: right;
}
