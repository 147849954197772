
.pmr-container {
    padding: 1em;
}

.pmr-link {
    display: flex;
    justify-content: center;
}

.pmr-page-content-container {
    width : 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
