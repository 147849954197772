@import "../../../app-customs/config-scss/variables.scss";


[id=helpers-page] p {

    // font-weight: bold;
    ons-button {
        margin: 2px 0 0 15px;
        cursor: pointer;
    }
}

// Onsenui hack
[id=helpers-page] .page__background {
    display: none;
}

[id=helpers-content] {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 5px 3px 0 3px;
    background-color: $homeBackgroundColor;

    .is-ios & {
        position: fixed;
        width: 100%
    }

    .is-ios .page-container.left & {
        width: $leftColumnsWidth;
    }

    .is-ios .page-container.right & {
        width: $rightColumnsWidth;
    }
}

.helpers-container {
    overflow: auto;
    --app-toolbar-height: 48px; //  to do $appToolbarHeight;
    height: calc(100% - var(--app-toolbar-height));
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;

    &.list-component {
        &.helpers {
            li {
                padding: 1rem 0;
            }
        }
    }
}

.helpers-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    object-fit: cover;
    height: 250px;
    width: 100%;
}

.helpers-description {
    position: relative;
    // background-color: #DE6066;
    border-radius: .54em;
    margin: 0.27em 0.5em 1em;
    margin-top: 5%;
    padding: 1em 0.6em;
    word-wrap: break-word;

    h3 {
        margin: 0 0 0.2rem;
    }

    p {
        margin: 0;
    }
}

.helpers-description h3 {
    margin: 10%;
}

.helpers-description-title {
    text-align: center;
    // color: white;
    font-size: 1.5em;
    font-weight: 300;
    // margin-top: 0%;
}

.helpers-description-text {
    text-align: left;
    // color: white;
    font-size: 1em;

    // padding-bottom: 4%;
    // padding-left: 2%;
    // padding-right: 2%;
    font-weight: 300;
}

.btn-container {
    margin-top: 20%;
    display: flex;
    flex-wrap: wrap;
}

.btn {
    align-self: center;
    flex-basis: 100px;
    padding: 10%;
    width: 200px;
    margin: 5px;
}