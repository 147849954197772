
@import "../../../app-customs/config-scss/variables.scss";

#disclaimer {
    position  : absolute;
    display   : flex;
    justify-content: space-around;
    z-index   : 10; // higher than left panel in two-columns mode
    bottom    : 0;
    left      : 50%;
    width     : 85%;
    max-width : 680px;
    transform : translate(-50%);
    margin-bottom: .5em;
    padding   : .4em .7em;
    background-color: white;
    border    : 1px solid $color1;
    box-shadow: 0 2px 0 #c3c1c1;
    box-shadow: 0px 2px 1px rgba(123, 123, 123, 0.5);
    overflow  : hidden;
    transition: bottom 1s, opacity 600ms;

    // Every child (content and button)
    * {
        min-height: 0;
        align-self: center;
        text-align: center;
    }

    #disclaimer-button {
        flex         : 0 0 55px;
        margin-left  : 1em;
        padding-top  : .2em;
        line-height  : 2.8em;
        font-weight  : bold;
        color        : #626262;
        border       : 1px solid $color1;
        border-radius: .4em;
        cursor       : pointer;
    }
}
