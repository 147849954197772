
@import "../../../../../app-customs/config-scss/variables.scss";


#kl-sort-search-bar {
    display: flex;
    flex: 0 0 $sortSearchBarHeight;
    align-items: center;
    padding: .5em 0 .4em;
    font-size: .95em;
}

.kl-ssb-sort-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 1em;
}

.kl-ssb-bydate-label,
.kl-ssb-bycompany-label {
    transition: opacity .5s;
}

.kl-ssb-label-faded {
    opacity: .5;
}

.kl-ssb-switch {
    margin: 0 .5em;
}

.kl-ssb-separator {
    height: 1.6em;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: $klTextColor;
}

.kl-ssb-search-btn {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    padding-right: .4em;
    font-size: 1.2em;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}