
@import "../../../../app-customs/config-scss/variables.scss";

.kl-tos {
    padding: .6em .6em .5em;
    border: 1px solid lighten( $klLinkBorderColor, 40% );
    
}

.custom-colored-link {
    color: $klLinkTextColor !important;
}
