@import "../../../app-customs/config-scss/variables.scss";
$radius : 0.5rem;
.event-page {

    .start-date,
    .start-time {
        display       : block;
        font-weight   : 600;
        color         : $fontColor;
    }

    .end-time {
        font-size   : 15px;
        font-weight : 500;
        margin-right: 5px;
    }

    .prop-left {
        display        : flex;
        align-items    : center;
        flex-basis     : 18%;
        justify-content: center;
        margin-right   : 13px;
    }

    .localisations li.message {
        margin   : 10px 0;
        font-size: 13px;
    }

    .prop-right.name-label {
        color: #595959;
    }

    .prop-right.evenements {
        display     : flex;
        margin-right: 1em;
        text-align  : center;
        justify-content: space-between;
    }

    .prop-right.location {
        display     : flex;
        margin-right: 1em;
        text-align  : center;
        justify-content: space-between;
        align-items: center;
    }

    span.start-date {
        display: inline-block;
        margin-bottom: 10px;
    }

    .end-time-to {
        margin: 0 5px;
    }

    .poll-btn-container {
        display: flex;
        flex-shrink: 0;
        margin-right: 0.3em;
        // margin-bottom: 1.1em;
        padding: .3em 0;
        justify-content: flex-end;
        clear          : both;
    }

    .poll-btn {
        padding       : .3em .7em .2em;
        font-weight   : bold;
        text-align    : center;
        text-transform: none;
        color         : white;
        border-radius : 3px;
        cursor        : pointer;
        background-color: $color2;
        transition    : opacity .4s;
        display: flex;
        align-items: center;
        opacity: 1;

        &:active {
            opacity: 0.3;
        }
    }

    .poll-btn-icon {
        margin-left: 0.7em;
        margin-bottom: -2px;

        &::before {
            color: white !important;
        }
    }

    .link.fa{
        font-size: 30px !important;
        line-height: 33px !important;
    }
}

 

.btn-live-stream {
     
    justify-content: center;
    align-items: center;
    border-radius: $radius;
    background-color: $color2;
    padding: 0.5rem 1rem;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    color : white;
        
     
}

.liveStream-icon{
    font-size: 2rem;
    width: $ficheIconsSize;
    text-align: end;
    color: white;
}



@media only screen and (min-width: 768px) {

    .event-page {

        .localisations li.message {
            margin    : 10px 0px;
            font-size : 18px;
            font-style: italic;
        }
        .link.fa{
            font-size: 30px !important;
            line-height: 40px !important;
        }
    }
}
