
@import "../../../../app-customs/config-scss/variables.scss";

#kl-ls {
    .kl-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20%;
        transition: padding-top .4s ease-out;
    }

    .kl-tos {
        margin: 1.5em 0 1em;
    }
}
#kl-ls.kl-ls-keyboard-visible .kl-content {
    padding-top: 0%;
}

.kl-ls-row {
    margin: 2em .8em;
    letter-spacing: .05em;
    flex: 0 0 75px;
    min-width: 270px;
}

$fieldHeight: 50px;

.kl-ls-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $fieldHeight;
    border: $klFieldBorder;

    input {
        height: inherit;
        padding: 0 1em;
        border: 0;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 120px;
    }
}

.is-ios .kl-ls-field input {
    font-size: 16px;
}

.kl-ls-scan-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 $fieldHeight;
    height: $fieldHeight;
    color: $klButtonsColor;
    cursor: pointer;
    background-color: $klButtonsBgColor;
    -webkit-tap-highlight-color: transparent;
}
.kl-ls-warning{

    color: $klwarningColor;

}
.kl-ls-field-warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $fieldHeight;
    border: $klFieldBorder;

    input {
        height: inherit;
        padding: 0 1em;
        border: 0;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 120px;
        outline:1px solid red;

    }
  
}
.is-ios .kl-ls-field-warning input {
    font-size: 16px;
}
