
@import "../../../../app-customs/config-scss/variables.scss";


#kl-form-screen {

    .kl-content {
        padding-bottom: 6em;
    }
}


.kl-fs-icon {
    flex-shrink: 0;
    display: inline-block;
    width: $klFormMarginLeft;
    height: $klFormMarginLeft;
    font-size: 1.15em;
    text-align: center;
}

.kl-fs-title {
    padding: 2.3em 2em 2.3em $klFormMarginLeft;
    font-weight: bold;
    font-size: .9em;
    background-color: $klFormTitleBgColor;
}

// Form row

.kl-content .kl-fs-field {
    display: flex;
    align-items: center;
    padding: 1.2em 1.5em 1.2em 0;
    border-bottom: 1px solid #efefef;
    font-size: .95em;
}

.kl-fs-icon-check {
    color: $klCheckColor;
}

.kl-fs-label-and-value {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0; // important for value overflow/ellipsis
}
.kl-fs-label {
    padding-right: .4em;
}
.kl-fs-highlight-mandatory-empty .kl-fs-label {
    font-weight: bold;
    color: $klMandatoryFieldNotSetColor;
}
.kl-fs-value {
    padding: .4em .4em 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.kl-fs-chevron {
    background-color: $klChevronColor;
}

.kl-content .kl-fs-field-switch {
    padding-right: 1em;

    .input-switch-container {
        margin: 0;
    }
}


.kl-fs-modal {
    .generic-field-row-chkbox {
        align-self: flex-start;
        padding-top: 0;
        background-color: $klCheckBoxContainerBgColor;
        font-size: 1.1em;

        .generic-chkbx {
            color: $klCheckBoxColor;
        }
    }
}



// History

.kl-fs-history {
    display: flex;
    margin: 2.5em 2em 0 $klFormMarginLeft;
    color: $klFormHistoryColor;
    font-size: .9em;
}

// History column1
.kl-fs-history-col1 {
    flex: 0 0 1.7em;
}
.kl-fs-history-icon {
    background-color: $klFormHistoryColor;
}

// History column 2
.kl-fs-history-col2 {

}
.kl-fs-history-title {
    padding-top: 1px;
}
.kl-fs-history-entries div {
    padding-top: .7em;
}
