
@import "../../../app-customs/config-scss/variables.scss";


.simple-loader {
    display    : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width      : 100%;
    height     : 100%;
    flex-grow  : 1;
    color      : $color1;
    font-size  : 1.2em;
    opacity: 0;
    transition : opacity .4s;

    div {
        margin: 0.3em 0;
        border-radius: 15px;
        //background-color: rgba(255, 255, 255, 0.9);
    }
}

.simple-loader-visible {
  opacity: 1;
}
.sync-icon-loader {
    background-color: $color1;
    width: 2em; 
    height: 2em;
}

// Dark theme
.dark .simple-loader {
    color: #bcbcbc;

    div {
        background-color: rgba(6, 16, 35, .8);
    }
}