@import "../../../app-customs/config-scss/variables.scss";
$searchFormToggleButtonBgColor: $color1;
$borderColor: rgb(216, 216, 216);
$marginBorder: 2em;
$lightGrey : lighten($lightGrey, 10%);
#search-taiga-page {
  .content-below-apptoolbar {
    display: flex;
    flex-direction: column;
    overflow: auto;
    @media screen and (min-width: 768px) {
      align-items: center;
      #search-form {
        min-width: 680px;
      }
    }
  }

  .alphalist-component {
    flex-shrink: 1;
    min-height: 0;
  }

  //   .search-form-advanced {
  //     // resize when keyboard is displayed:
  //     flex-shrink: 1;
  //     overflow-y: auto;
  //   }
  .list-component,
  .simple-loader {
    flex-basis: 30%;
  }
  /* 
   *---------------------------------
   * overwrite style for new UI zeplin
   *---------------------------------
   */
  .container-searchForm {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto; // scroll when keyboard is displayed
  }

  #search-form {
    margin: auto 0;
    max-width: 768px;
    // min-height: 320px;
    align-items: stretch;
    // flex: 1;
    padding: 0;
    justify-content: space-between;
    
  }
  .list-empty{
    margin: 0 0 auto;
  }
  #sfs-toggle-btns {
    flex: 1;
    width: 100%;
    border-radius: 0;
    text-transform: uppercase;
    div {
      display: flex;
      flex: auto;
      justify-content: space-evenly;
      align-items: center;
      padding: 2em;
      transition: 200ms ease;
    }
  }

  .sfs-tb-selected {
    opacity: 1;
    pointer-events: none;
    cursor: default;
    &:after {
      font-family: "Font Awesome 5 Pro";
      font-weight: 500;
      font-size: 1em;
      content: "\f00c";
    }
  }
  #sfs-go-to-advanced-btn {
    margin: auto;
    .-round {
      border-radius: 0.47rem;
      padding: 0.5rem;
    }
    width: 85%;
    justify-content: flex-end;
    & i {
      margin-left: 0.5rem;
    } 
  }
  #sfs-search-btn {
    margin: 2rem 2rem 0.5rem 2rem;
  }
  #sfs-go-to-simple-btn {
    justify-content: end;
    margin: 0.7rem 2em 1em;
    & i {
      margin-right: 0.5rem;
    }
  }

  .cta-btn {
    // add from ui project zeplin
    &.-round {
      color: black;
      border: 1px solid $ctaBtnBackgroundColor;
      font-weight: 500;
      font-size: 1.17em;
      //   letter-spacing: 0.1rem;
      background-color: transparent;
      border-radius: 2rem;
      box-shadow: inset 0 0 5px rgba(#000, 0.16);
      padding: 1.2rem;
      flex: 1;
    }
    &.btn-as-link {
      // overwrite cta-button > alike link
      border: none;
      background-color: transparent;
      color: $color1;
      font-size: 0.7rem;
      text-decoration: underline;
      font-weight: 400;
    }
    &.btn-as-text {
      @extend .btn-as-link;
      text-decoration: none;
      padding: 0.2rem 0;
      font-size: 1em;
      display: flex;
      align-items: center;
      line-height: 1;
      & span {
        height: 1em;
        line-height: 0.9;
      }
      &.onResult {
        & span {
          height: 100%;
          line-height: 1;
        }
      }
    }
  }
  .search-form-simple {
    .generic-field-row {
      .input-search {
        width: 85%;
      }
      input {
        outline: none;
        flex: 1;
        padding: 1rem;
        border-radius: 0;
        border: 1px solid $borderColor;
      }
      .input-with-label {
        .input-label {
          width: 85%;
          margin-left: 0rem;
        }
      }
    }
    #sfs-search-btn-small.cta-btn-container {
      padding: 0;
      > .cta-btn {
        // cta on searchBox
        display: flex;
        align-items: center;
        flex: 1;
        padding: 1em;
        // font-size: 1.2em;
        border-radius: 0;
        border: none;
      }
    }
    #sfs-go-to-advanced-btn {
      margin-top: 0.7em;
    }
    .input-group {
      align-items: center;
      margin-left: 0;
    }

    section {
      margin: auto 0 0;
      // padding-bottom: 1em;
      // border-bottom: 1px solid $lightGrey;
    }
    .input-with-clear {
      position: relative;
      width: 100%;
      display: flex;
      .search-field-clear {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  
  .generic-field-row {
    align-items: center;
    width: 100%;
    margin-top: 1em;
    input {
      flex: 1;
    }
    .input-search {
      display: flex;
      flex: 1;
      input {
        margin: 0;
      }
    }

    .input-with-label {
      flex-direction: column;
      .input-label {
        margin-left: 1rem;
      }
    }

    .input-group {
      display: flex;
      width: 85%;
      // justify-content: flex-start;
      // align-items: center;
      flex: 1;
      input {
        // min-width: 0; // fix size on Iphone SE
        // width: 100%;
        // margin-left: $marginBorder; // place when input-group
      }
    }
    .input-bottom-border {
      input {
        border: 0;
      }
      border-bottom: 1px solid $lightGrey;
    }

    > i {
      margin-left: $marginBorder;
    }
  }
  .input-bottom-border {
    input {
      border: 0;
    }
    border-bottom: 1px solid $lightGrey;
  }
  // SEARCH ADVANCED
  .search-form-advanced {
    section {
      display: block;
    }
    .generic-field-row {
      input {
        outline: none;
        margin: 0;
        padding: 0.3rem 0 0.5rem 0;
        border: 0;
      }
      .input-search {
        position: relative;
        input {
          padding: 0.5em 1em;
        }
      }
      border: none;
    }
    .generic-field-row-chkbox {
      margin: 0 auto;
      padding-top: 0;
      background-color: $pageBackgroundColor;
      > div {
        flex-basis: auto;
      }
    }
    .result-value {
      display: flex;
      flex-wrap: wrap;
      margin-right: 1em;
      .item-checkbox {
        font-size: 0.7em;
        @media only screen and (max-width: 768px) {
          font-size: 0.6em;
        }
        color: #555;
        // line-height: 2.4;
      }
    }
  }

  .input-group {
    display: flex;
    margin-left: 1rem;
    &-checkbox {
      i {
        padding-top: 0.15em;
        margin: 0 1rem 0 1em;
      }
      label {
        padding-top: 0.15em;
        flex-basis: 1.8em;
      }
    }
  }
  .hasClick {
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
  .search-field-clear {
    z-index: 999;
    position: absolute;
    top: 50%;
    right: 0em;
  }
}

.input-modal {
  .generic-field-row-chkbox {
    background-color: $pageBackgroundColor; //color on checkbox modal
    text-align: left;
    div{
      flex: 1; // fix long content on label ios
    }
  }
  &-inner {
    .input-with-clear {
      position: relative;
      width: 100%;
      display: flex;
      .search-field-clear {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1em;
      }
    }
  }
  
}
