
@import "../../../app-customs/config-scss/variables.scss";


#klipso-leads-page{
    color: $klTextColor;

    & > div {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

}
