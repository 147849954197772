@import "../../../app-customs/config-scss/variables.scss";

// Warning: also defined in JS due to computation issues
// (sometimes offsetHeight: 0 despite a call to requestAnimationFrame after component render/update)


.app-toolbar.theme-dark {
    color: white;
    background-color: $color2;
    box-shadow: none;

    .app-toolbar-icon {
        color: white;

        .star {
            color: white;
        }

        svg,
        path {
            fill: white;
        }
    }
}

.app-toolbar {
    display: flex !important;
    justify-content: space-between;
    height: $appToolbarHeight;
    width: 100%;
    background-color: $appToolbarBackgroundColor;

    .material-icons {
        font-size: 1em;
        opacity: 1;
    }

    .left {
        display: flex;
        flex-basis: 0;
    }

    .center {
        display: flex;
        // vertically center content
        flex-direction: column;
        justify-content: space-around;
        flex-grow: 1;
        padding: .1em .3em 0;
        line-height: $appToolbarHeight;
        font-size: 1em;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        user-select: none;

        // contextual title
        span {
            font-size: .9em;
        }

        @media screen and (max-width: 320px) {
            &.content-font {
                font-size: 0.8rem;
            }
        }

    }

    .right {
        display: flex;
        justify-content: flex-end;
        width: auto;
        min-width: auto;
        margin-right: .6em;
    }

    .app-toolbar-icon {
        display: flex;
        align-items: center;
        font-size: 1.2em;
        color: $appToolbarColor;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        span {
         /*    margin: 0 .1em;
            padding: 0 .1em; */
        }

        .star {
            color: $favIconColor;
        }

        svg,
        path {
            fill: $appToolbarColor;
            margin: 0 .1em;
            padding: 0 .1em;
        }
    }

    .toolbar-back-bt {
        padding: 0 .3em 0 .5em; // easier user interaction
    }

    .list-icon {
        font-size: 22px;
    }
}

.content-below-apptoolbar {
    height: calc(100% - #{$appToolbarHeight});
}

// DROP SHADOW
.app-toolbar.at-with-dropshadow {
    position: relative;
    z-index: 2;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .3);

    .content-below-apptoolbar {
        position: relative;
        z-index: 1;
    }
}


// iphone X special case
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 2),
screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    .is-ios .app-toolbar {
        top: 0;
        // padding-top: env(safe-area-inset-top);
    }
}


// SYNCHRO FAV BUTTON
.synchro-fav-btn {
    display: inline-block;
    display: flex !important;

    svg,
    path {
        fill: #c0c0c0 !important;
        stroke: none !important;
    }
}

.synchro-fav-btn-ongoing {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    svg,
    path {
        fill: #666e74 !important;
        stroke: none !important;
    }

}

.synchro-fav-btn-failed {

    svg,
    path {
        fill: #FF0614 !important;
        stroke: none !important;
    }

}

.synchro-fav-btn-success {

    svg,
    path {
        fill: #0c9e0c !important;
        stroke: none !important;
    }

}

.is-ms .app-toolbar {
    height: $appToolbarHeight;

    .right {
        padding: .4em;
    }
}

.filter-enabled {
    color: $fdSelectedIcon;
}