@font-face {
  font-family: 'project';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/project.woff') format('woff'),
       url('../public/files/project/fonts/project.ttf') format('ttf');
}

@font-face {
  font-family: 'proxima-nova';
  font-weight: normal;
  font-style : regular;
  src: url('../public/files/project/fonts/ProximaNova-Regular.ttf') format('woff'),
       url('../public/files/project/fonts/ProximaNova-Regular.ttf')  format('ttf');
}

@font-face {
  font-family: 'proxima-nova';
  font-weight: bold;
  font-style : regular;
  src: url('../public/files/project/fonts/ProximaNova-Bold.woff') format('woff'),
       url('../public/files/project/fonts/ProximaNova-Bold.ttf') format('ttf');
}

.title-font,
.content-font {
  font-family: 'proxima-nova';
}

.icon-font {
  font-family: 'project';
}

input, .generic-input, ::-webkit-input-placeholder {
  @extend .content-font;
}