
@import "../../../app-customs/config-scss/variables.scss";


.img-upload {
    margin: 1em;

    #iu-input {
        display: none;
    }
}

.iu-preview-container {
    display: flex;
    justify-content: space-around;
}
.iu-preview {
    position: relative;
}

.iu-preview-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    text-align: center;
    background-color: #eeeeee;
    user-select: none;
}

$clearBtnColor: grey;
.iu-preview .iu-clear-btn {
    position: absolute;
    right: -6px;
    bottom: -6px;
    display: flex;
    justify-content: center;
    width: 1em;
    height: 1em;
    font-size: 1.7em;
    color: $clearBtnColor;
    background-color: white;
    border: 1px solid $clearBtnColor;
    border-radius: 50%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.iu-cordova-source-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0 1em 0;
}
.iu-cordova-source-buttons > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6em;
    padding: .5em 1em;
    margin: .5em 1em;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    i {
        font-size: 5em;
        padding-bottom: .05em;
    }
}

.iu-cordova-source-camera-btn {
    background-color: $imageUploadCameraBtnColor;
}
.iu-cordova-source-gallery-btn {
    background-color: $imageUploadGalleryBtnColor;
}
