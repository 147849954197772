

.fiche.newproduct {

    .prop-left {
        display        : flex;
        align-items    : center;
        flex-basis     : 29%;
        justify-content: center;
        margin-left    : 13px;
    }
}

.pdfs-title {
    padding: 0 1.1em;

    h2 {
        font-size: 1.1em !important;
        font-weight: bold;
    }
}