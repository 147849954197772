
@import "../../../app-customs/config-scss/variables.scss";


.list-content-container {
    display       : flex;
    flex-direction: column;
    overflow-y    : hidden;
    -webkit-overflow-scrolling: touch;
}

.list-page-content {
    display       : flex;
    flex-direction: column;
    flex-grow     : 1;
    flex-shrink   : 1;
    overflow-y    : auto;

    & > div {
        flex-shrink: 0;
    }
}

