
@import "../../../../app-customs/config-scss/variables.scss";



.kl-main .kl-content {
    display: flex;
    height: 100%;
}

// List sorted by modification date
//   !! when side index position is like on mockup.
//      temporarily disabled - needs commit revert !!
.kl-main-scrollable {
    flex-grow: 1;
    overflow-y: auto;
}

// List sorted by company
.kl-main-not-scrollable {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    .kl-main-list-container {
        flex-shrink: 1;
        min-height: 0;
        display: flex;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        & > :first-child {
            flex-grow: 1;
            height: inherit;
        }

        // Grouped by company
        .alphalist-component {
            overflow: hidden;
        }

        // Grouped by date
        .items-list {
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
}


.kl-list-empty {
    text-align: center;
    padding-top: 5em;
}

.kl-list-icon-sync,
.kl-list-icon-notsync {
    padding: 0 1.6em 0 0.6em;
    font-size: .9em;
}
.kl-list-icon-sync {
    color: $klCheckColor;
}
.kl-list-icon-notsync {
    color: $klNotSyncColor;
}

.kl-main .list-el-chevron {
    color: $klChevronColor;
    padding-right: 1em;
}
