
@import "../../../app-customs/config-scss/variables.scss";



// Row style (row = start block, dest block, poi block)

.ps-row {
    // min-width: 270px;
    padding-bottom: .3em;

    .search-field-container {
        padding: .6em .2em .6em .4em;

        input {
            // min-width: 220px;
            padding: .4em .3em .3em .4em;
        }
    }

    .search-field-clear {
        margin: .3em .1em .2em .2em;
    }

    .sp-btn-position {
        display: flex;
        justify-content: space-around;
        margin: .4em 0 .2em;

        span {
            background-color: $color1;
            color: white;
            padding: .3em .6em .2em;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}

.ps-bar {
    color: white;
    background-color: $color1 !important;
    &.modal {
        padding:50px;
        padding: 0 50px 0 50px;
        box-sizing: border-box;
    }
}


.ps-selected {
    display: flex;
    padding: .7em .4em .6em;
}
.ps-selected-text {
    flex-grow: 1;
    min-width: 0;

    // Common for text and text-minor
    div {
        text-overflow: ellipsis;
        overflow     : hidden;
        white-space  : nowrap;
    }
}
.is-ms .ps-selected-text {
    width    : 90%; // Fix IE11
}


.ps-edit-button {
    padding-left: .3em;
    background-color: $color1;
    &:before {
        padding: .1em .2em .2em .3em;
        border-radius: 10px;
        cursor: pointer;
    }
}
