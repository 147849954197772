
@import "../../../app-customs/config-scss/variables.scss";


[id=search-page-container] {
    display: flex;
    flex-direction: column;
}

[id=search-page-container] .search-results {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

[id=search-page-container] .search-field-container {
    box-shadow : 0 1px 3px rgba(0,0,0,.3);

    .search-field-input-container{
        flex: 0.90;
    }
    .search-field-clear-container{
        flex: 0.1;
    }
}
