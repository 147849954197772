
@import "../../../../app-customs/config-scss/variables.scss";



// Common style
.kl-ci-contact-data-row1,
.kl-ci-contact-data-row2 {
    padding: .9em 1.5em .7em 0;
    font-size: .9em;

    & > div {
        display: flex;
        align-items: center;
        margin: .5em 0;
    }
}


// Row 1

.kl-ci-contact-data-row1 {
    background-color: $klFormContactRow1BgColor;
}

.kl-ci-icon-company {
    background-color: $klFormCompanyIconColor;
}
.kl-ci-company {
    color: $klFormCompanyNameColor;
    font-size: 1.2em;
    font-weight: bold;
}

.kl-ci-icon-name {
    background-color: $klFormNameIconColor;
}
.kl-ci-name {
    font-weight: bold;
}
.kl-ci-firstname {
    margin-right: .3em;
}
.kl-ci-lastname {
    text-transform: uppercase;
}

.kl-ci-role {
    font-weight: bold;
    font-style: italic;
}


// Row 2

.kl-ci-contact-data-row2 {
    background-color: $klFormContactRow2BgColor;
    border-bottom: $klFormContactRow2BorderBottom;
}

.kl-ci-icon-other {
    background-color: $klFormIconOtherColor;
    font-size: 1em;
}

.kl-ci-icon-phone {
    font-size: 1em;
}

.kl-ci-other {
    color: $klFormIconOtherColor;
    font-size: 1em;
}

.phone-row-st1 {
    fill: $klFormIconOtherColor;
    stroke: none;
}

.phone-row-st0 {
    fill: none; 
    stroke: none;
}