
@import "../../../../app-customs/config-scss/variables.scss";


.kl-ems-licence-username-row {
    display: flex;
    flex-direction: column;
    padding: 1em 1em .8em 2em;
    // border-bottom: $klEmsHorizontalBorder;
}

.kl-ems-row {
    display: flex;
    align-items: center;
    margin: .6em 0;
}

.kl-ems-r-icon {
    flex: 0 0 1.6em;
    background-color: $klEmsLuIconsColor;
}
.kl-ems-r-label {
    flex-grow: 1;
    color: $klEmsLuLabelsColor;
}
.kl-ems-r-value {
    text-align: right;
    color: $klEmsLuValuesColor;
}
.kl-versionNumber{
    text-align: right;
    margin-right: 20px;
}
.kl-ems-reset-licence {
    align-self: flex-end;
    margin: 1.7em 0 .8em;
    font-weight: bold;
    font-size: .8em;
    font-style: italic;
    text-decoration: underline;
    color: $klEmsResetLicenceColor;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.kl-ems-export {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em 2em 1.4em 1.8em;
    // border-bottom: $klEmsHorizontalBorder;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
.kl-ems-export-label {
    font-weight: bold;
    font-size: .9em;
}
.kl-ems-export-icon {
    background-color: $klEmsExportIconColor;
}

.kl-ems-tos {
    display: flex;
    justify-content: center;
    margin-top: .5em;
}