
@import "../../../app-customs/config-scss/variables.scss";

.mobilespot-button {
    position : absolute;
    z-index  : 1;
    right    : .4em;
    bottom   : .7em;
    font-size: .7em;
    opacity  : .6;
    cursor   : pointer;

    a {
        padding: .2em .4em .1em .3em;
        background-color: white;
        border-radius: .4em;
        border: 2px solid $color1;
    }
}